import React from 'react';

function NotFoundPage() {
  return (
    <div className="p-5">
      <h1>404 - Not found</h1>
      <p>This page is not found.</p>
    </div>
  );
}

export default NotFoundPage;
