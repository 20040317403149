import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React from 'react';
import NavigationProps from '../../models/NavigationProps';
import NavigationLink from '../NavigationLink/NavigationLink';
import styles from './Navigation.module.scss';

const Navigation = ({
  menuTitle = '',
  navigationLinks,
  collapsibleOnResponsive = false,
}: NavigationProps) => {
  console.log(styles);
  const navbarOptions = collapsibleOnResponsive ? { expand: 'lg' } : {};
  return (
    <Navbar {...navbarOptions} className={`${styles.navbar}`}>
      <Container fluid>
        {menuTitle !== '' && <Navbar.Brand href="#">{menuTitle}</Navbar.Brand>}
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              {menuTitle}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 ml-auto">
              <ul>
                {navigationLinks.map(navigationLink => (
                  <li key={navigationLink.props.name}>
                    <NavigationLink
                      name={navigationLink.props.name}
                      path={navigationLink.props.path}
                    />
                  </li>
                ))}
              </ul>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navigation;
