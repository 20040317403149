import React from 'react';
import './Footer.module.scss'; // Import your styles here

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p>© {currentYear} - Beam Cloud UI</p>
    </footer>
  );
};

export default Footer;
