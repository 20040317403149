import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Route, Routes } from 'react-router-dom';
import AppProps from './models/AppProps';
import { headerMenu } from './constants/menus';
import NotFoundPage from './containers/not-found-page/NotFoundPage';

const App = ({ isIframe }: AppProps) => {
  return (
    <div className="App">
      {!isIframe && (
        <Header
          title="Beam Cloud UI"
          tagline="Beta"
          navigationLinks={headerMenu}
        />
      )}

      <Routes>
        {headerMenu.map(NavigationLink => (
          <Route
            key={NavigationLink.props.name}
            path={NavigationLink.props.path}
            Component={NavigationLink.props.component}
          />
        ))}
        <Route key="404" path="*" Component={NotFoundPage} />
      </Routes>

      {!isIframe && <Footer />}
    </div>
  );
};

export default App;
