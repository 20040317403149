import React from 'react';
import logo from '../../../assets/images/logo.svg';
import styles from './Header.module.scss';
import '../../../styles/_mixins.scss';
import Navigation from '../../../shared/components/Navigation/Navigation';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderProps from '../../../shared/models/HeaderProps';

const Header = ({ title, tagline, navigationLinks }: HeaderProps) => {
  return (
    <header className={styles.header}>
      <Container fluid>
        <Row>
          <Col className="d-flex align-items-center" xs={9}>
            <Col className={styles.logoTitleContainer}>
              <Row>
                <Col xs={8}>
                  <img src={logo} alt="logo" className={styles.logo} />
                </Col>
                <Col xs={4}>
                  <div className={styles.div}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.tagline}>{tagline}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col className="d-flex justify-content-end" xs={3}>
            <Navigation
              navigationLinks={navigationLinks}
              collapsibleOnResponsive={true}
            />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
