import React from 'react';

function HomePage() {
  return (
    <div className="p-5">
      <h1>HomePage</h1>
      <p>This is the HomePage.</p>
    </div>
  );
}

export default HomePage;
