import HomePage from '../containers/home-page/HomePage';
import React from 'react';
import NavigationLink from '../../shared/components/NavigationLink/NavigationLink';
import AboutPage from '../containers/about-page/AboutPage';
import NavigationLinkProps from '../../shared/models/NavigationLinkProps';

const homeNavigationLink: React.ReactElement<NavigationLinkProps> = (
  <NavigationLink path="/" component={HomePage} name="Home"></NavigationLink>
);

const aboutNavigationLink: React.ReactElement<NavigationLinkProps> = (
  <NavigationLink
    path="/about"
    component={AboutPage}
    name="About"
  ></NavigationLink>
);

export const headerMenu: React.ReactElement<NavigationLinkProps>[] = [
  homeNavigationLink,
  aboutNavigationLink,
];
