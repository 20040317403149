import React from 'react';

function AboutPage() {
  return (
    <div className="p-5">
      <h1>About Us</h1>
      <p>This is some information about our company.</p>
    </div>
  );
}

export default AboutPage;
