import NavigationLinkProps from '../../models/NavigationLinkProps';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import styles from './NavigationLink.module.scss';

const NavigationLink = ({ path, name }: NavigationLinkProps) => {
  return (
    <Nav.Link as={NavLink} to={path ?? ''} className={`${styles.link}`}>
      {name}
    </Nav.Link>
  );
};

export default NavigationLink;
